export default class Environment
{
	public static Variables: { [name: string]: string } = {};

	public static async Init(): Promise<void>
	{
		try
		{
			var response = await fetch("/api/env");

			if (response.ok)
				this.Variables = await response.json();
		}
		catch (e)
		{
			let ex: Error = e as Error;
			console.log(ex);

			// TODO: Change to production
			this.Variables =
			{
				"ASPNETCORE_ENVIRONMENT": "Development"
			}
		}
	}
}
