import React from "react";
import Package from "../../package.json";

import { Checkbox, Link, Spinner, Text, Title2, tokens } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";
import { Helmet } from "react-helmet";

import QRCode from "qrcode.react";

import DownloadDialog from "../Components/DownloadDialog";

import { ReactComponent as StandWithUkraine } from "../Assets/StandWithUkraine.svg";

import CommunicationService from "../Utils/CommunicationService";
import { NavigateLink, NavigationProps, RouteWrapper } from "../Utils/Navigation";
import Environment from "../Utils/Environment";

import ConnectionInfo from "../Models/ConnectionInfo";
import ICredential from "../Models/ICredential";

import "./StartView.scss";

interface IStates
{
	IsReconnecting: boolean;
	ConnectionInfo?: ConnectionInfo;
	IsError: boolean;
}

class StartView extends React.Component<NavigationProps, IStates>
{
	private comms: CommunicationService = new CommunicationService();

	constructor(props: any)
	{
		super(props);

		this.state =
		{
			IsReconnecting: false,
			IsError: false
		};

		this.comms.OnConnectionStatusChanged = (isReconnecting: boolean) => this.setState({ IsReconnecting: isReconnecting });
		this.comms.OnDataReceived = (data: ICredential) => this.OnDataReceived(data);
	}

	private OnDataReceived(data: ICredential): void
	{
		this.props.navigate("/result", { state: data });
	}

	public async componentDidMount(): Promise<void>
	{
		console.log("StartView.componentDidMount");

		try
		{
			let info: ConnectionInfo = await this.comms.Start();
			this.setState({ ConnectionInfo: info, IsReconnecting: false });
		}
		catch (e: any)
		{
			let ex: Error = e;
			console.error(`StartView.componentDidMount: ${ex.name}`, ex.message, ex.stack, ex.cause);
			this.setState({ IsError: true });
		}
	}

	public async componentWillUnmount(): Promise<void>
	{
		console.log("StartView.componentWillUnmount");
		await this.comms.Stop();
	}

	public render(): JSX.Element
	{
		return (
			<main className="center start-view">
				{ this.state.IsError ?
					<Alert
						intent="error"
						className="scaleUpIn"
						action={ { children: "Retry", onClick: () => document.location.reload() } }>

						Check your connection
					</Alert>
					:
					<section className={ this.props.location.state as string ?? "slideUpIn" }>
						<header>
							<Title2 as="h1">
								EasyLogon
								{ (Package.version.includes("alpha") || Package.version.includes("beta")) &&
									<sup>{ Package.version.includes("alpha") ? "α" : "β" }</sup>
								}
							</Title2>
							<Text as="h2">Sign in on any device with a few clicks</Text>
						</header>

						{ this.state.IsReconnecting &&
							<Alert className="slideUpIn" intent="warning">Reconnecting to the server...</Alert>
						}

						<div className="qr-placeholder">
							<QRCode
								className="fadeIn"
								style={ { animationDelay: "0.5s" }}
								value={ this.state.ConnectionInfo?.Uri?.href ?? "ezl://send/HelloWorld?key=1234567890" }
								renderAs="svg"
								size={ 300 }
								fgColor={ tokens.colorNeutralForeground1 }
								bgColor="transparent"
								includeMargin />

							{ (this.state.IsReconnecting || !this.state.ConnectionInfo) &&
								<div className="spinner-block fadeIn">
									<Spinner aria-busy aria-label="Connecting to the server" size="huge" />
								</div>
							}
						</div>

						<Text align="center">
							By using the service you accept the <Link as="a" href="/privacy" onClick={ (e) => NavigateLink("/privacy", e, this.props.navigate) }>privacy policy</Link>
						</Text>
						<Text>
							1. Open <Link as="a" href="#download" onClick={ (e) => NavigateLink("#download", e, this.props.navigate, { state: this.props.location.state }) }>EasyLogon app</Link> on your phone<br />
							2. Scan the QR code<br />
							3. Select which account to use<br />
							3. Paste your login/password on login page
						</Text>

						<DownloadDialog />

						<footer>
							<StandWithUkraine />
							<Text weight="semibold">#StandWithUkraine</Text>
						</footer>

						<Helmet>
							<title>EasyLogon</title>
							<meta property="og:title" content="EasyLogon" />
						</Helmet>
					</section>
				}

				{/* Debug info */ }
				{ (Environment.Variables["ASPNETCORE_ENVIRONMENT"] === "Development") &&
					<Text className="test-debug" as="pre">
						<b>Encryption key:</b> <span>{ this.state.ConnectionInfo?.EncryptionKey ?? "null" }</span><br />
						<b>Connection ID:</b> <span>{ this.state.ConnectionInfo?.ConnectionId ?? "null" }</span><br />
						<b>Status:</b> <span>{ this.comms.GetState() }</span><br />
						<br />
						<Checkbox label="Reconnecting" checked={ this.state.IsReconnecting } onClick={ () => this.setState({ IsReconnecting: !this.state.IsReconnecting }) } /><br />
						<Checkbox label="Error" checked={ this.state.IsError } onClick={ () => this.setState({ IsError: !this.state.IsError }) } /><br />
						<Checkbox label="Connected" checked={ this.state.ConnectionInfo ? true : false } onClick={ () => this.setState({ ConnectionInfo: this.state.ConnectionInfo ? null : new ConnectionInfo("AAAbbbCCCddd") }) } /><br />
						<button onClick={ () => this.OnDataReceived({ Login: "eugene@xfox111.net", Password: "P@$$w0rd" }) }>Fetch</button><br />
						<button onClick={ () => this.OnDataReceived({ }) }>Fetch (error)</button><br />
					</Text>
				}
			</main>
		);
	}
}

export default RouteWrapper(StartView);
