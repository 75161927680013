import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import ConnectionInfo from "../Models/ConnectionInfo";
import ICredential from "../Models/ICredential";
import CryptoJS from "crypto-js";
import Environment from "./Environment";

export default class CommunicationService
{
	public OnConnectionStatusChanged: (isReconnecting: boolean) => any = () => { };
	public OnDataReceived: (data: ICredential) => any = () => { };

	public ConnectionInfo: ConnectionInfo;

	private connection: HubConnection = new HubConnectionBuilder()
		.withAutomaticReconnect()
		.withUrl("/api/comms", { headers: { "Content-Type": "charset=utf-8" } })
		.configureLogging(Environment.Variables["ASPNETCORE_ENVIRONMENT"] === "Production" ? LogLevel.Information : LogLevel.Debug)
		.build();

	public GetState(): HubConnectionState
	{
		return this.connection.state;
	}

	public async Start(): Promise<ConnectionInfo>
	{
		this.connection.onreconnecting(() => this.OnConnectionStatusChanged(true));
		this.connection.onreconnected(() => this.OnConnectionStatusChanged(false));
		this.connection.on("ReceiveData", (data) => this.ProcessData(data));

		await this.connection.start();

		return this.ConnectionInfo = new ConnectionInfo(this.connection.connectionId);
	}

	public async Stop(): Promise<void>
	{
		this.connection.off("ReceiveData");
		await this.connection.stop();
	}

	private ProcessData(encryptedData: string): void
	{
		var data: ICredential = null;
		try
		{
			var raw: string = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Base64.parse(this.ConnectionInfo.EncryptionKey),
				{
					mode: CryptoJS.mode.ECB,
					padding: CryptoJS.pad.Pkcs7
				})
				.toString(CryptoJS.enc.Utf8);

			data = JSON.parse(raw);

		}
		catch { }

		this.OnDataReceived(data);
	}
}
