import React from "react";

import { Button, Text, tokens } from "@fluentui/react-components";
import { Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components/unstable";

import QRCode from "qrcode.react";

import { NavigateLink, NavigationProps, RouteWrapper } from "../Utils/Navigation";

import "./DownloadDialog.scss";

class DownloadDialog extends React.Component<NavigationProps>
{
	public render(): JSX.Element
	{
		return (
			<Dialog
				open={ this.props.location.hash.toLocaleLowerCase() === "#download" }
				onOpenChange={ (e) => this.props.navigate("/", { state: this.props.location.state }) }>

				<DialogSurface className="download-dialog fadeIn">
					<DialogTitle>Download EasyLogon app on your phone</DialogTitle>

					<DialogBody className="dialog-body">
						<Text as="p">Our mobile app allows you to store all your passwords in one place, and send them to other devices via QR code</Text>
						<QRCode
							value={ `${document.location.protocol}://${document.location.hostname}/get-app` }
							renderAs="svg"
							className="qrcode"
							size={ 262 }
							fgColor={ tokens.colorNeutralForeground1 }
							bgColor="transparent" />
					</DialogBody>

					<DialogActions>
						<DialogTrigger>
							<Button as="a" appearance="secondary">Close</Button>
						</DialogTrigger>
						<Button as="a" href="/get-app" onClick={ (e) => NavigateLink("/get-app", e, this.props.navigate) } appearance="primary">Download</Button>
					</DialogActions>
				</DialogSurface>
			</Dialog>
		);
	}
}

export default RouteWrapper(DownloadDialog);
