import CryptoJS from "crypto-js";

export default class ConnectionInfo
{
	public ConnectionId: string;
	public EncryptionKey: string;
	public Target: string;
	public readonly Uri: URL;

	constructor(connectionId: string)
	{
		this.ConnectionId = connectionId;
		this.EncryptionKey = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Base64);
		this.Target = document.referrer;
		this.Uri = this.GetUri();
	}

	private GetUri(): URL
	{
		var url: URL = new URL(`ezl://send/${this.ConnectionId}`);
		url.searchParams.append("key", this.EncryptionKey);
		if (this.Target)
			url.searchParams.append("target", new URL(this.Target).host);

		return url;
	}
}
