import React from "react";
import Package from "../../package.json";

import { Button, Image, Link, Text, Title2, Title3 } from "@fluentui/react-components";
import { ChevronLeftRegular } from "@fluentui/react-icons";

import { SocialIcon } from "react-social-icons";
import { Helmet } from "react-helmet";

import { ReactComponent as BuyMeACoffee } from "../Assets/BuyMeACoffee.svg";

import { NavigateLink, NavigationProps, RenderTitle, RouteWrapper } from "../Utils/Navigation";

import "./AboutView.scss";

class AboutView extends React.Component<NavigationProps>
{
	public render() : JSX.Element
	{
		let query : URLSearchParams = new URLSearchParams(document.location.search);
		let appVersion : string = query.get("appVersion");

		return (
			<main className="about-view">
				<section className="slideUpIn">
					<header>
						{ !appVersion &&
							<Button
								as="a" href="/"
								onClick={ (e) => NavigateLink("/", e, this.props.navigate, { state: "slideLeftIn" }) }
								icon={ <ChevronLeftRegular /> } appearance="transparent">

								Back
							</Button>
						}

						<Title2 as="h1">EasyLogon</Title2>

						<Text>Version { Package.version }</Text>
						{ appVersion &&
							<Text>Application version: { appVersion }</Text>
						}
					</header>

					<Text>Sign in on any device with a few clicks</Text>
					<Text>Developed by: <Link href="https://linkedin.com/in/xfox">Eugene Fox</Link></Text>

					<div className="blocks">
						<div>
							<Title3>Special thanks</Title3>
							<Text>Anastasia Godunova</Text>
							<Text>Max Parmenov</Text>
							<Text>Alexander Ilyin</Text>
							<Text>Kaz</Text>
							<Text>And all testers ❤️</Text>
						</div>

						<div>
							<Title3>Have any ideas?</Title3>
							<Text>Share them with me!</Text>
							<Text>Email them to <Link href="mailto:feedback@xfox111.net">feedback@xfox111.net</Link> or contact me on any social media below!</Text>
						</div>

						<div>
							<Title3>Like the project?</Title3>
							<Text>Support me with a donation. Even a small amount is a big deal</Text>
							<Button
								className="bmc"
								as="a" target="_blank"
								href="https://buymeacoffee.com/xfox111"
								appearance="primary" icon={ <BuyMeACoffee /> }>

								Buy me a coffee
							</Button>
						</div>
					</div>

					<div className="socials">
						<SocialIcon target="_blank" fgColor="white" label="LinkedIn" url="https://linkedin.com/in/xfox" />
						<SocialIcon target="_blank" fgColor="white" label="Discord" url="https://discordapp.com/users/471341335887085569" />
						<SocialIcon target="_blank" fgColor="white" label="Blog" url="https://blog.xfox111.net" network="rss" />
						<SocialIcon target="_blank" fgColor="white" label="Telegram" url="https://t.me/xfox111" />
						<SocialIcon target="_blank" fgColor="white" label="Reddit" url="https://reddit.com/u/xfox111" />
						<SocialIcon target="_blank" fgColor="white" label="GitHub" url="https://github.com/xfox111" />
						<SocialIcon target="_blank" fgColor="white" label="Twitter" url="https://twitter.com/xfox111" />
						<SocialIcon target="_blank" fgColor="white" label="Facebook" url="https://facebook.com/xfox111" />
						<SocialIcon target="_blank" fgColor="white" label="Instagram" url="https://instagram.com/xfox.111" />
						<SocialIcon target="_blank" fgColor="white" label="VK" url="https://vk.com/eugene.xfox" />
						<SocialIcon target="_blank" fgColor="white" label="YouTube" url="https://www.youtube.com/channel/UC3H2q5Ss_CR3wP_S1zV8joA" />
					</div>

					<Text>©{ new Date().getFullYear() } Eugene Fox</Text>
				</section>

				<Image className="fadeIn" src="/img/Logo.svg" alt="EasyLogon logotype" />

				<Helmet>
					<title>{ RenderTitle("About") }</title>
					<meta property="og:title" content="About EasyLogon project" />
				</Helmet>
			</main>
		);
	}
}

export default RouteWrapper(AboutView);
