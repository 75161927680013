import React from "react";

import { Button, CompoundButton, Image, Text } from "@fluentui/react-components";
import { ChevronLeftRegular } from "@fluentui/react-icons";

import { isAndroid, isIOS } from "react-device-detect";
import { Helmet } from "react-helmet";

import "./DownloadView.scss";
import { NavigateLink, NavigationProps, RenderTitle, RouteWrapper } from "../Utils/Navigation";

interface IPlatformLink
{
	Platform: string;
	Logo: string;
	Link?: string;
	IsCurrent: boolean;
}

class DownloadView extends React.Component<NavigationProps>
{
	private links: IPlatformLink[] =
		[
			{ Platform: "Android", Logo: "/img/Android.svg", Link: "https://play.google.com/store/apps/details?id=com.foxdev.studio.easylogon", IsCurrent: isAndroid },
			{ Platform: "iOS", Logo: "/img/Apple.svg", Link: null, IsCurrent: isIOS },
		];

	public componentDidMount(): void
	{
		console.log("DownloadView.componentDidMount");

		for (let i of this.links)
			if (i.IsCurrent && i.Link)
			{
				document.location.assign(i.Link);
				break;
			}
	}

	public render(): JSX.Element
	{
		return (
			<main className="download-view center">
				<section>
					{ this.links.map(i =>
						<CompoundButton className="fadeIn" as="a" key={ i.Platform } disabled={ !i.Link } href={ i.Link } target="_blank">
							<div>
								<Image title={ i.Platform } src={ i.Logo } />
								<Text size={ 500 } weight="semibold">{ i.Link ? i.Platform : "Coming later" }</Text>
							</div>
						</CompoundButton>
					) }
				</section>

				<Button as="a" href="/" onClick={ (e) => NavigateLink("/", e, this.props.navigate, { state: "slideLeftIn" }) } appearance="transparent" icon={ <ChevronLeftRegular /> }>Back</Button>

				<Helmet>
					<title>{ RenderTitle("Download app") }</title>
					<meta property="og:title" content="Download EasyLogon" />
					<meta name="description" content="Download EasyLogon app to manage your passwords and sign in anywhere with a few clicks" />
					<meta property="og:description" content="Download EasyLogon app to manage your passwords and sign in anywhere with a few clicks" />
				</Helmet>
			</main>
		);
	}
}

export default RouteWrapper(DownloadView);
