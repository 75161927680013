import React from "react";

import { Button, Tooltip } from "@fluentui/react-components";
import { InfoRegular } from "@fluentui/react-icons";

import { NavigateLink, NavigationProps, RouteWrapper } from "../Utils/Navigation";

class AboutButton extends React.Component<NavigationProps>
{
	public render(): JSX.Element
	{
		let query : URLSearchParams = new URLSearchParams(document.location.search);

		// If page is displayed in mobile app, this button should be hidden
		if (query.has("appVersion"))
			return <></>;

		return (
			<Tooltip
				content="About project"
				positioning={ "before-top" }
				relationship="label">

				<Button
					id="about-btn"
					appearance="subtle"
					as="a" href="/about"
					onClick={ (e) => NavigateLink("/about", e, this.props.navigate) }
					icon={ <InfoRegular /> } />
			</Tooltip>
		);
	}
}

export default RouteWrapper(AboutButton);
