import React from "react";

import { Button, Input, Popover, PopoverSurface, Text, Title3, Tooltip } from "@fluentui/react-components";
import { CheckmarkCircleRegular, CheckmarkRegular, ChevronLeftRegular, CopyRegular, ErrorCircleRegular, EyeOffRegular, EyeRegular, KeyRegular, PersonRegular } from "@fluentui/react-icons";

import { isMobile } from "react-device-detect";

import { NavigateLink, NavigationProps, RenderTitle, RouteWrapper } from "../Utils/Navigation";

import ICredential from "../Models/ICredential";

import { ReactComponent as BuyMeACoffee } from "../Assets/BuyMeACoffee.svg";

import "./ResultView.scss";
import { Helmet } from "react-helmet";

interface IStates
{
	LoginIcon: JSX.Element;
	PasswordIcon: JSX.Element;
	Credential?: ICredential;
	RevealPassword: boolean;
}

class ResultView extends React.Component<NavigationProps, IStates>
{
	constructor(props: NavigationProps)
	{
		super(props);

		this.state =
		{
			LoginIcon: <CopyRegular className="scaleUpIn" />,
			PasswordIcon: <CopyRegular className="scaleUpIn" />,
			RevealPassword: false,
			// Change false to true to see success page for debug purposes
			Credential: false ? { Login: "xfox111", Password: "Password" } : props.location.state as ICredential
		};
	}

	private CopyCredential(target: "Login" | "Password"): void
	{
		if (!document.hasFocus())
			return;

		console.log(`ResultView.CopyCredential: ${target}`);

		navigator.clipboard.writeText(this.state.Credential[target]);

		this.setState({ ...this.state, [`${target}Icon`]: <CheckmarkRegular className="scaleUpIn" /> });
		setTimeout(() => this.setState({ ...this.state, [`${target}Icon`]: <CopyRegular className="scaleUpIn" /> }), 3000);
	}

	public componentDidMount(): void
	{
		console.log("ResultView.componentDidMount");

		if (this.state.Credential?.Password)
			this.CopyCredential("Password");
	}

	public render(): JSX.Element
	{
		let data: ICredential = this.state.Credential;

		if (!data?.Password)
			return (
				<main className="center result-view">
					<div className="slideRightIn">
						<header className="error">
							<ErrorCircleRegular />
							<Title3 as="h1">Something went wrong</Title3>
							<Text>Please go back and try again</Text>
						</header>

						<Button
							as="a" href="/"
							onClick={ (e) => NavigateLink("/", e, this.props.navigate, { replace: true, state: "slideLeftIn" }) }
							appearance="transparent" icon={ <ChevronLeftRegular /> }>

							Try again
						</Button>
					</div>

					<Helmet>
						<title>{ RenderTitle("Something went wrong") }</title>
					</Helmet>
				</main>
			);

		return (
			<main className="center result-view">
				<div className="slideRightIn">
					<header className="success">
						<CheckmarkCircleRegular />
						<Title3 as="h1">Account information received!</Title3>
					</header>

					<section>
						{ data.Login &&
							<Input
								value={ data.Login } placeholder="Username" readOnly
								contentBefore={ <PersonRegular /> }
								contentAfter={
									<Tooltip
										relationship="label" content="Copy"
										positioning="after" withArrow>

										<Button
											onClick={ () => this.CopyCredential("Login") }
											appearance="subtle" icon={ this.state.LoginIcon } />
									</Tooltip>
								} />
						}

						<Input
							type={ this.state.RevealPassword ? "text" : "password" }
							value={ data.Password } readOnly placeholder="Password"
							contentBefore={ <KeyRegular /> }
							contentAfter={
								<>
									<Tooltip
										relationship="label" content={ this.state.RevealPassword ? "Hide password" : "Reveal password" }
										positioning="below" withArrow>

										<Button
											onClick={ () => this.setState({ RevealPassword: !this.state.RevealPassword }) }
											appearance="subtle" icon={ this.state.RevealPassword ? <EyeOffRegular /> : <EyeRegular /> } />
									</Tooltip>
									<Tooltip
										relationship="label" content="Copy"
										positioning="after" withArrow>

										<Button
											onClick={ () => this.CopyCredential("Password") }
											appearance="subtle" icon={ this.state.PasswordIcon } />
									</Tooltip>
								</>
							} />
					</section>
				</div>

				<Button
					as="a" href="/"
					onClick={ (e) => NavigateLink("/", e, this.props.navigate, { replace: true, state: "slideLeftIn" }) }
					appearance="transparent" icon={ <ChevronLeftRegular /> }>

					Back
				</Button>

				<Popover
					open={ !isMobile } withArrow	// This callout is displayed incorrectly on mobile devices, so I hid it for now
					appearance="brand"
					positioning={
						{
							position: "before",
							align: "bottom",
							target: document.querySelector<HTMLElement>("#about-btn")
						}
					} >

					<PopoverSurface className="callout-content fadeIn">
						<Title3>🧡 Like the service?</Title3>
						<Text>Help us to improve by submitting an idea 💡 or supporting with a donation 💰</Text>
						<Button
							className="bmc"
							as="a" target="_blank"
							href="https://buymeacoffee.com/xfox111"
							appearance="primary" icon={ <BuyMeACoffee /> }>

							Buy me a coffee
						</Button>
					</PopoverSurface>
				</Popover>

				<Helmet>
					<title>EasyLogon</title>
				</Helmet>
			</main>
		);
	}
}

export default RouteWrapper(ResultView);
