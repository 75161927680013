import React from "react";

import { FluentProvider, Theme, webDarkTheme, webLightTheme } from "@fluentui/react-components";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";

import AboutButton from "./Components/AboutButton";

import StartView from "./Views/StartView";
import AboutView from "./Views/AboutView";
import DownloadView from "./Views/DownloadView";
import PrivacyView from "./Views/PrivacyView";
import ResultView from "./Views/ResultView";

import "./App.scss";

interface IStates
{
	Theme: Theme;
}

export default class App extends React.Component<any, IStates>
{
	constructor(props: any)
	{
		super(props);

		this.state =
		{
			Theme: this.UpdateTheme()
		};
	}

	public componentDidMount(): void
	{
		window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (arg: MediaQueryListEvent) => this.setState({ Theme: this.UpdateTheme(arg.matches) }));
	}

	private UpdateTheme(isDark?: boolean): Theme
	{
		let theme: Theme = (isDark ?? window.matchMedia("(prefers-color-scheme: dark)").matches) ? webDarkTheme : webLightTheme;
		document.body.style.backgroundColor = theme.colorNeutralBackground1;

		return theme;
	}

	public render(): JSX.Element
	{
		return (
			<FluentProvider theme={ this.state.Theme } targetDocument={ document }>
				<BrowserRouter>
					<Routes>
						<Route path="/about" element={ <AboutView /> } />
						<Route path="/get-app" element={
							<>
								<DownloadView />
								<AboutButton />
							</>
						} />
						<Route path="/privacy" element={
							<>
								<PrivacyView />
								<AboutButton />
							</>
						} />
						<Route path="/result" element={
							<>
								<ResultView />
								<AboutButton />
							</>
						} />
						<Route path="/" element={
							<>
								<StartView />
								<AboutButton />
							</>
						} />
					</Routes>

					<Helmet>
						<meta name="theme-color" content={ this.state.Theme.colorNeutralBackground1 } />
					</Helmet>
				</BrowserRouter>
			</FluentProvider>
		);
	}
}
