import React from "react";

import { Button, Text, Title2 } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";
import { ChevronLeftRegular } from "@fluentui/react-icons";

import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";

import { GithubGist } from "../Models/GithubGist";

import { NavigateLink, NavigationProps, RenderTitle, RouteWrapper } from "../Utils/Navigation";

import "./PrivacyView.scss";
import Environment from "../Utils/Environment";

interface IStates
{
	Policy?: GithubGist;
	IsError: boolean;
}

class PrivacyView extends React.Component<NavigationProps, IStates>
{
	constructor(props: any)
	{
		super(props);

		this.state =
		{
			IsError: false
		};
	}

	public async componentDidMount(): Promise<void>
	{
		console.log("PrivacyView.componentDidMount");

		try
		{
			var response: Response = await fetch("https://api.github.com/gists/b3e3ea9900f0f72edbb4ba7d8259fc13");

			if (response.ok)
				this.setState({ Policy: await response.json(), IsError: false });
		}
		catch
		{
			this.setState({ IsError: true });
		}
	}

	public render(): JSX.Element
	{
		let query : URLSearchParams = new URLSearchParams(document.location.search);

		return (
			<main className="privacy-view fadeIn">
				<header>
					{ !query.has("appVersion") &&
						<Button
							as="a" href="/"
							onClick={ (e) => NavigateLink("/", e, this.props.navigate, { state: "slideLeftIn" }) }
							appearance="transparent" icon={ <ChevronLeftRegular /> }>

							Back
						</Button>
					}

					<Title2 as="h1">Privacy policy</Title2>

					{ this.state.Policy &&
						<Text className="fadeIn">Last update: { new Date(this.state.Policy.updated_at).toDateString() }</Text>
					}

					{ this.state.IsError &&
						<Alert
							style={ { marginTop: 10 } }
							intent="error"
							action={ { children: "Retry", onClick: () => document.location.reload() } }>

							Something went wrong. Try again later
						</Alert>
					}
				</header>

				{ this.state.Policy &&
					<div className="slideUpIn">
						<article>
							<ReactMarkdown
								children={ this.state.Policy?.files["EasyLogonPrivacyPolicy.md"]?.content }
								linkTarget="_blank" />
						</article>

						<footer>
							<Text>©{ new Date().getFullYear() } Eugene Fox</Text>
						</footer>
					</div>
				}

				{/* Debug info */ }
				{ (Environment.Variables["ASPNETCORE_ENVIRONMENT"] === "Development") &&
					<Text className="test-debug" as="p">
						<button onClick={ () => this.setState({ IsError: false, Policy: null }) }>Reset view</button>
						<button onClick={ () => this.setState({ IsError: true }) }>Show error</button>
						<button onClick={ () => this.componentDidMount() }>Reload policy</button>
					</Text>
				}

				<Helmet>
					<title>{ RenderTitle("Privacy policy") }</title>
					<meta property="og:title" content="EasyLogon privacy policy" />
				</Helmet>
			</main>
		);
	}
}

export default RouteWrapper(PrivacyView);
