import { Location, NavigateFunction, NavigateOptions, useLocation, useNavigate } from "react-router-dom";

export function NavigateLink(path: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, nav: NavigateFunction, options?: NavigateOptions)
{
	event.preventDefault();
	nav(path, options);
}

export interface NavigationProps
{
	navigate: NavigateFunction;
	location: Location;
}

export const RouteWrapper = (Component: any) =>
{
	function Wrapper(props: any)
	{
		const navigate: NavigateFunction = useNavigate();
		const location: Location = useLocation();

		return (
			<Component
				navigate={ navigate }
				location={ location }
				{ ...props }
			/>
		);
	};

	return Wrapper;
};

export function RenderTitle(title: string, overridePostfix?: string): string
{
	const mediaQuery: MediaQueryList = window.matchMedia("(display-mode: standalone)");

	return title + (mediaQuery.matches ? "" : overridePostfix ?? " - EasyLogon");
}
